<template>
  <div class="row">
    <div :class="'col-lg-12'">
      <div class="card">
        <div class="card-body">
          <!-- <form class="form-horizontal"> -->
          <form action="#" @submit.prevent="showModal({type:'submit', data:payload.addedUser})">
            <div class="form-body">
              <h3 class="box-title">Disposisi</h3>
              <hr class="m-t-0">
              <template>
                <div class="row">
                  <div class="col-md-12">
                    <label :class="['control-label',  'col-md-12']">
                      Nomor Agenda
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label :class="['control-label',  'col-md-12']">
                      <b>{{  this.surat.agenda_number }}</b>
                    </label>
                  </div>
                </div>
                <hr class="m-t-10">


                <div class="row">
                  <div class="col-md-12">
                    <label :class="['control-label',  'col-md-12']">
                      Perihal
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label :class="['control-label',  'col-md-12']">
                      <b>{{  this.surat.subject }}</b>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label :class="['control-label',  'col-md-12']">
                      Diteruskan Kepada
                      <small style="color:red">*</small>
                    </label>
                  </div>
                </div>

                <div class="row" v-if="userDisposisi.length > 0">
                  <div class="col-md-8">
                    <div class="form-group">
                      <label :class="['control-label', {'text-right': !isMobile}, 'col-md-1']"></label>
                      <div class="col-md-9 col-sm-9">
                        <div class="checkbox">
                          <input
                            @click="checkAll()"
                            v-model="isCheckAll"
                            id="index"
                            type="checkbox"
                          >
                          <label for="index">Centang Semua</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div :class="'col-md-6'" v-for="(value, index) in userDisposisi" :key="index">
                    <div class="row">
                      <label :class="['control-label', {'text-right': !isMobile}, 'col-md-1']"></label>
                      <div :class="'col-md-6 col-sm-12'">
                        <div>
                          <input
                            :id="'checkbox-a-'+index"
                            :value="value"
                            v-model="addedUserFirst"
                            v-if="addedUser[index]"
                            @change="updateValue($event, index)"
                            type="checkbox"
                            class="m-r-5"
                          >
                          <input
                            class="m-r-5"
                            :id="'checkbox-'+index"
                            :value="value"
                            v-model="addedUser[index]"
                            @change="updateCheckall($event, index)"                            
                            type="checkbox"
                          >
                          <label :for="'checkbox-'+index">{{value.text}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="m-t-10">
                <div class="row">
                  <div class="col-md-12">
                    <label :class="['control-label','col-md-12']">
                      Personal
                      <small style="color:red">*</small>
                    </label>
                    <div :class="[{ 'invalid': isInvalidPersonal },'col-md-12']">
                      <Multiselect
                        v-model="payload.personal"
                        label="text"
                        track-by="value"
                        placeholder="Pilih Personal"
                        open-direction="bottom"
                        :options="dropdownPersonal.personalList"
                        :multiple="true"
                        :searchable="true"
                        :value="dropdownPersonal.value"
                        :loading="dropdownPersonal.isLoadingPersonal"
                        :internal-search="false"
                        :clear-on-select="true"
                        :close-on-select="true"
                        :options-limit="300"
                        :max-height="600"
                        :show-no-results="true"
                        :hide-selected="true"
                        @input="onChangePersonal"
                        @search-change="personal"
                      >
                        <span slot="noResult">Oops! No Data found.</span>
                      </Multiselect>
                      <label
                        class="typo__label form__label"
                        v-show="isInvalidPersonal"
                      >Must have at least one value</label>
                    </div>
                  </div>
                </div>
                <hr class="m-t-10">
                <div class="row">
                  <div class="col-md-12">
                    <label :class="['control-label',  'col-md-12']">
                      Perintah Disposisi
                      <small style="color:red">*</small>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div :class="'col-md-6'" v-for="(row, index) in disposisiCommand" :key="index">
                    <div class="row">
                      <div class="col-md-12">
                      <label :class="['control-label',  'col-md-12']">
                        <strong>{{ row.text }}</strong>
                        <div v-for="(r, i) in row.data" :key="i">
                          <div class="checkbox">
                          <input
                            :value="r.value"
                            v-model="addedCommand"
                            :id="r.value"
                            type="checkbox"
                          >
                          <label :for="r.value">{{r.text}}</label>
                        </div>                        
                        </div>
                      </label>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template>
                <div class="row m-t-10">
                  <div class="col-md-12">
                    <label :class="['control-label',  'col-md-12']">Catatan Disposisi</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 m-t-10 m-b-10">
                    <wysiwyg v-model="myHTML"/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label :class="['control-label', 'col-md-12']">File Lampiran</label>
                      <div class="col-md-12 col-sm-12">
                        <Gallery :uploader="uploader"/>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <hr>
            <div class="form-actions">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-offset-3 col-md-9">
                      <button
                        :disabled="(addedUser.length === 0 && payload.personal.length === 0) || disposisiCommand.length === 0"
                        type="button"
                        @click="submit(false)"
                        class="btn btn-success"
                        title="Simpan"
                      >Simpan</button>&nbsp;
                      <button
                        :disabled="(addedUser.length === 0 && payload.personal.length === 0) || disposisiCommand.length === 0"
                        type="button"
                        @click="submit(true)"
                        class="btn btn-success"
                        title="Submit"
                      >Kirim</button>&nbsp;
                      <!-- <button type="button" @click="goBack()" class="btn btn-inverse">Cancel</button> -->
                      <button type="button" @click="showModal({type:'cancel'})" class="btn btn-inverse" title="Cancel">Cancel</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-6"></div>
              </div>
            </div>
          </form>
          <ModalDefault v-show="isVisible" :title="modalTitle" :content="modalContent" :action="ActionChangeable" :confirmation="isConfirmation" @close="onClose" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "@/components/input/upload.vue";
import axios from "axios";
import store from "@/store";
import moment from "moment";

export default {
  components: {
    Upload,
    Input,
    Multiselect
  },
  computed: {
    isInvalid() {
      return this.isTouched && this.value.length === 0;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    isInvalidPersonal() {
      return (
        this.dropdownPersonal.isTouched &&
        this.dropdownPersonal.value.length === 0
      );
    }
  },
  data() {
    const uploader = this.fineUploader(this, "disposition_in");
    return {
      uploader,
      surat:{},
      files: [],
      myHTML: "",
      value: [],
      user: [],
      payload: {
        agenda_number: "",
        personal: [],
        photos: ""
      },
      addedUser: [],
      addedUserFirst: [],
      userDisposisi: [],
      commandUser: [],
      disposisiCommand: [],
      dropdownPersonal: {
        isTouched: false,
        personalList: [],
        value: [],
        isLoadingPersonal: false
      },
      addedCommand: [],
      isCheckAll: false,
      isDisabled: false,
      isModalVisible: false,
      isVisible: false,
      modalTitle: '',
      modalContent:'',
      isConfirmation:false,
      isInformation:false,
      ActionChangeable:null
    };
  },
  async mounted() {
    var query = "";
    this.getIntruksi(query);
    this.getUserDispo(query);
    this.personal(query);
    this.initialize();
  },

  methods: {
    onClose(){
      this.isVisible = false;
    },
    showModal(option){
      this.isVisible = true;
      if(option.type == 'submit'){
        if(!empty(option.data)){
          this.modalTitle = "Simpan Teruskan Disposisi?";
          this.modalContent = "<p>Apakah anda yakin ingin meneruskan disposisi?</p>";
          this.isConfirmation = true;
          this.ActionChangeable = this.submit;
        }
      }else if(option.type == 'cancel'){
        this.modalTitle = "Kembali?";
        this.modalContent = "<p>Apakah anda yakin kembali?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goBack;
      }
    },
    async initialize() {
      try {
        // this.$store.dispatch(
        //   "documentIn/getDocumentInById",
        //   this.$route.params
        // );
        // this.$store.dispatch("documentIn/onDisposisi", this.$route.params);
        axios.get(`/disposition_in/${this.$route.params.dispoid}`).
          then(response => {
            this.surat = response.data.data.surat;  
            this.payload.agenda_number = this.surat.agenda_number;   
        })   
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    // checkAll() {
    //   this.isCheckAll = !this.isCheckAll;
    //   this.addedUser = [];
    //   this.addedUserFirst = [];
    //   if (this.isCheckAll) {
    //     for (var key in this.userDisposisi) {
    //       this.addedUser.push(this.userDisposisi[key]);
    //     }
    //   }
    // },
    checkAll() {
      this.isCheckAll = !this.isCheckAll;
      this.addedUser = [];
      this.addedUserFirst = [];
      if (this.isCheckAll) {
        for (var key in this.userDisposisi) {
          this.userDisposisi[key].defaultValue = 1;
          this.addedUser.push(this.userDisposisi[key]);
        }
      }
    },
    updateValue(e, index) {
      if (e.target.checked) {
        this.addedUser[index].defaultValue = 2;
      } else {
        this.addedUser[index].defaultValue = 1;
      }
    },
    updateCheckall(e, index) {
      let data = e.target._value;

      if (this.addedUser[index] && data) {
        this.addedUser[index] = data;
      }
      let tempData = [];
      this.addedUser.forEach(value => {
        if (value != null || value != false || value != undefined) {
          tempData.push(value);
        }
      });
      if (tempData.length == this.userDisposisi.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },
    findValue(data) {
      return data.value === 1;
    },
    changeDefaultValueAsOne(data) {
      return (data.defaultValue = 1);
    },
    changeDefaultValueAsTwo(data) {
      return (data.defaultValue = 2);
    },
    // updateCheckall() {
    //   if (
    //     this.addedUserFirst.find(this.findValue) ===
    //     this.addedUser.find(this.findValue)
    //   ) {
    //     this.addedUser.find(this.changeDefaultValueAsTwo);
    //   } else {
    //     if (this.addedUserFirst.length > 0 && this.addedUser.length === 0) {
    //       this.addedUser = this.addedUserFirst;
    //       this.addedUser.find(this.changeDefaultValueAsTwo);
    //     } else {
    //       this.addedUser.find(this.changeDefaultValueAsOne);
    //     }
    //   }

    //   if (this.addedUser.length == this.userDisposisi.length) {
    //     this.isCheckAll = true;
    //   } else {
    //     this.isCheckAll = false;
    //   }
    // },
    goBack() {
      this.$store.dispatch("disposisi/onCancel");
    },
    submit(send) {
      console.log(this);
      
      if (this.payload.photos) {
        this.myFile = this.payload.photos.split(",");
      }
      const addedUser = [];
      this.addedUser.forEach(value => {
        if (value != null || value != false || value != undefined) {
          addedUser.push(value);
        }
      });
      const payload = {
        dispoid: this.$route.params.dispoid,
        agenda_number: this.payload.agenda_number,
        user: addedUser,
        is_send: send,
        disposisi: this.addedCommand,
        personal: this.payload.personal,
        files: this.files,
        description: this.myHTML,
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then(success => {
        if (success) {
          this.$store.dispatch("dispositionIn/submitTeruskan", data);
        }
      });
    },

    personal(query) {
      this.dropdownPersonal.isLoadingPersonal = true;
      axios
        .get(`document_in/list?s=${query}`)
        .then(res => {
          this.dropdownPersonal.personalList = res.data.items;
          this.dropdownPersonal.isLoadingPersonal = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    getUserDispo(query) {
      var user = JSON.parse(localStorage.getItem("user"));
      axios
        .post(`disposition_in/disposition?s=${query}&structural=${user.structural_position_id}&unit_id=${user.unit_id}`)
        .then(res => {
          res.data.items.forEach(row => {
            // console.log(row.value);
            if(row.value!=user.unit_id){
              this.userDisposisi.push({
                value: row.value,
                text: row.text,
                defaultValue: 1
              });
            }
            // console.log(user.unit_id);
          });
        });
    },
    getIntruksi() {
      axios.get(`document_statuses/list`).then(res => {
        this.disposisiCommand = res.data.items;
      });
    },
    onTouchPersonal() {
      this.dropdownPersonal.isTouched = true;
    },
    onChangePersonal(value) {
      this.dropdownPersonal.value = value;
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

